import { useState, useEffect } from 'react';

const useMinLengthValidation = (str, minLength) => {
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(str.length >= minLength);
  }, [str, minLength]);

  return isValid;
};

export default useMinLengthValidation;