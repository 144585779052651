import StartContainer from "../../fragments/Containers/StartContainer";
import MainFragment from "../../fragments/PagesFragments/MainFragment/MainFragment";

function MainPage({ toggleTheme }) {
    return (
        <StartContainer toggleTheme={toggleTheme}>
            <MainFragment />
        </StartContainer>
    );
}

export default MainPage;