import { useSpring } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import { easings } from '@react-spring/web';

const useAnimatedItem = (distance = 100, direction = 'rtl', duration = 500, delay = 2000, onAnimationEnd) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    const getTransform = () => {
        switch (direction) {
            case 'ltr':
                return `translateX(-${distance}px)`;
            case 'rtl':
                return `translateX(${distance}px)`;
            case 'ttb':
                return `translateY(-${distance}px)`;
            case 'btt':
                return `translateY(${distance}px)`;
            default:
                return 'translateX(0)';
        }
    };

    const animationProps = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateX(0)' : getTransform(),
        config: { duration, easing: easings.easeOutQuad },
        delay,
        onRest: onAnimationEnd,
    });

    return { ref, animationProps };
};

export default useAnimatedItem;
