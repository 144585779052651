import { useState, useEffect } from 'react';
import { useEmailValidation, useMinLengthValidation, usePhoneValidation } from "../../../utils/Validation";
import useSendData from "../../../hooks/useSendData";

const useEmailDrawerForm = () => {
    const { sendData, loading, error, data } = useSendData();
    const [phoneMask, setPhoneMask] = useState(['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        whatsapp: '',
        subject: '',
        message: ''
    });

    const [touched, setTouched] = useState({
        name: false,
        email: false,
        phone: false,
        whatsapp: false,
        subject: false,
        message: false
    });

    const isEmailValid = useEmailValidation(formData.email);
    const isPhoneValid = usePhoneValidation(formData.phone);
    const isNameValid = useMinLengthValidation(formData.name, 3);
    const isSubjectValid = useMinLengthValidation(formData.subject, 5);
    const isMessageValid = useMinLengthValidation(formData.message, 10);

    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        setIsDisabled(!(isEmailValid && isPhoneValid && isNameValid && isSubjectValid && isMessageValid));
    }, [isEmailValid, isPhoneValid, isNameValid, isSubjectValid, isMessageValid]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!isDisabled) {
            await sendData('SendEmailController.php', formData, 'POST', true);
            resetForm();
        }
    };

    const handleChange = (value, name) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleBlur = (name) => {
        setTouched((prevTouched) => ({
            ...prevTouched,
            [name]: true
        }));
    };

    const updateMask = (value) => {
        const numericValue = value.replace(/\D/g, ''); // Remove os caracteres "não numéricos"
        if (numericValue.length >= 10) {
            setPhoneMask(['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]);
        } else {
            setPhoneMask(['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]);
        }
    };

    const handleChangePhone = (value) => {
        setFormData((prevData) => ({
            ...prevData,
            phone: value
        }));
        updateMask(value);
    };

    const resetForm = () => {
        setFormData({
            name: '',
            email: '',
            phone: '',
            whatsapp: '',
            subject: '',
            message: ''
        });
        setTouched({
            name: false,
            email: false,
            phone: false,
            whatsapp: false,
            subject: false,
            message: false
        });
    };

    return {
        formData,
        phoneMask,
        isDisabled,
        loading,
        error,
        data,
        handleChange,
        handleChangePhone,
        handleBlur,
        handleSubmit,
        resetForm,
        isEmailValid,
        isPhoneValid,
        isNameValid,
        isSubjectValid,
        isMessageValid,
        touched
    };
};

export default useEmailDrawerForm;
