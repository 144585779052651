import "./main-fragment.css";
import AnimatedItem from "../../../components/AnimatedItem/AnimatedItem";
import Card from "../../../components/Cards/CardLanguage";
import { Divider, FlexboxGrid } from "rsuite";

function MainFragment() {
    return (
        <>
            <FlexboxGrid justify="center" align="middle" className="cn-vertical-space">
                <div className="cn-center-container" style={{ marginTop: '10%', width: '65%', height: '50%', backgroundColor: 'rgba(255, 255, 255, 0.05)' }}>
                    <div className="cn-large"></div>
                    <div className="merriweather-xlight cn-logo"><AnimatedItem direction={'ltr'} duration={500} distance={500} delay={700}>CARLOS</AnimatedItem></div>
                    <Divider />
                    <div className="merriweather-black cn-logo"><AnimatedItem direction={'rtl'} duration={500} distance={0} delay={900}>DUARTE</AnimatedItem></div>
                    <div><AnimatedItem direction={'ltr'} duration={2000} distance={0} delay={1200}>CONSTRUINDO O <strong><em>FUTURO DA WEB</em></strong>, UM CÓDIGO DE CADA VEZ</AnimatedItem></div>
                </div>
            </FlexboxGrid>
            <FlexboxGrid justify="center" align="middle" className="cn-margin-menostop">
                <Divider><h1>Sobre Mim</h1></Divider>
                <div className="cn-horizontal-container cn-max-width">
                    <div className='cn-max-width-50 cn-center-container cd-animated-div' data-central-height="200" data-max-offset="-100" data-offset-position="0" data-direction="horizontal" data-opacity-transition="1" data-transform-transition="0.5">
                        <div className="cn-xlarge cn-margin-top">Carlos Duarte</div>
                        <div className="cn-large cn-margin-top">Desenvolvedor web em início de carreira, com habilidades em React e PHP.
                            Tenho grande facilidade de aprendizagem e muita motivação para enfrentar novos desafios.
                            Além da experiência prática adquirida em projetos pessoais e acadêmicos,
                            possuo habilidades de liderança e organização desenvolvidas em funções como Chefe de Sala e Coordenador de ALA em concursos públicos de importantes bancas, como FGV, Cesgranrio, Furb e Fepese.
                            Estou ansioso para aplicar meus conhecimentos e crescer profissionalmente, contribuindo para o sucesso de projetos inovadores e desafiadores.</div>
                    </div>
                    <div className='cn-max-width-50 cd-animated-div' data-central-height="200" data-max-offset="100" data-offset-position="0" data-direction="horizontal" data-opacity-transition="1" data-transform-transition="0.5">
                        <img src="./src/img/fotos/carlosduarte.png" />
                    </div>
                </div>
            </FlexboxGrid>
            <div className="cn-background-body cn-margin-top">
                <Divider><h2>Formação Acadêmica</h2></Divider>
                <div className="cn-horizontal-container cn-max-width">
                    <div className='cn-max-width-50 cn-center-container cd-animated-div' data-central-height="00" data-max-offset="-100" data-offset-position="0" data-direction="horizontal" data-opacity-transition="1" data-transform-transition="0.5">
                        <div className="cn-xlarge cn-margin-top">Graduação</div>
                        <div className="cn-large cn-margin-top">2023: Graduando em Sistema de Informação pela Universidade Federal de Santa Catarina - UFSC. Florianopolis, Brasil</div>
                    </div>
                    <div className='cn-max-width-50 cd-animated-div' data-central-height="00" data-max-offset="100" data-offset-position="0" data-direction="horizontal" data-opacity-transition="1" data-transform-transition="0.5">
                        <img src="./src/img/site/icons/ufsc.png" />
                    </div>
                </div>
                <Divider></Divider>
                <div className="cn-horizontal-container cn-max-width">
                    <div className='cn-max-width-50 cd-animated-div' data-central-height="00" data-max-offset="-100" data-offset-position="0" data-direction="horizontal" data-opacity-transition="1" data-transform-transition="0.5">
                        <img src="./src/img/site/icons/ifsc.png" />
                    </div>
                    <div className='cn-max-width-50 cn-center-container cd-animated-div' data-central-height="00" data-max-offset="100" data-offset-position="0" data-direction="horizontal" data-opacity-transition="1" data-transform-transition="0.5">
                        <div className="cn-xlarge cn-margin-top">Curso técnico</div>
                        <div className="cn-large cn-margin-top">2000: Curso Técnico de Mecânica pela antiga Escola Técnica Federal de Santa Catarina, hoje Instituto Federal de Santa Catarina - IFSC. Florianópolis, Brasil</div>
                    </div>
                </div>
            </div>
            <div className="cn-margin-menostop">
                <Divider><h1>Desenvolvimento</h1></Divider>
                <div className="cn-max-width cn-margin-top">
                    <div className="cn-large">
                        Essas tecnologias são essenciais para o desenvolvimento moderno de software e aplicativos WEB, cada uma contribuindo de maneira única para a criação de aplicações robustas, interativas e escaláveis.
                    </div>
                    <div className="cn-horizontal-container cn-flex-wrap">
                        <div className='cd-animated-div' data-central-height="700" data-max-offset="50" data-offset-position="0" data-direction="horizontal" data-opacity-transition="1" data-transform-transition="0.2">
                            <Card imageSrc="./src/img/site/icons/html.png" text="É a linguagem usada para criar páginas da web. Com o HTML, você estrutura o conteúdo, como texto, imagens e links." />
                        </div>
                        <div className='cd-animated-div' data-central-height="700" data-max-offset="50" data-offset-position="0" data-direction="horizontal" data-opacity-transition="1" data-transform-transition="0.4">
                            <Card imageSrc="./src/img/site/icons/css.png" text="É usado para estilizar páginas da web. Com o CSS, você define cores, fontes, margens e outros estilos." />
                        </div>
                        <div className='cd-animated-div' data-central-height="700" data-max-offset="50" data-offset-position="0" data-direction="horizontal" data-opacity-transition="1" data-transform-transition="0.6">
                            <Card imageSrc="./src/img/site/icons/js.png" text="Uma linguagem de programação executada no navegador usada para tornar as páginas interativas." />
                        </div>
                        <div className='cd-animated-div' data-central-height="700" data-max-offset="50" data-offset-position="0" data-direction="horizontal" data-opacity-transition="1" data-transform-transition="0.8">
                            <Card imageSrc="./src/img/site/icons/react.png" text="Uma biblioteca JavaScript para criar interfaces de usuário dinâmicas e reutilizáveis." />
                        </div>
                        <div className='cd-animated-div' data-central-height="700" data-max-offset="50" data-offset-position="0" data-direction="horizontal" data-opacity-transition="1" data-transform-transition="1.0">
                            <Card imageSrc="./src/img/site/icons/php.png" text="Uma linguagem de programação usada no lado do servidor para criar aplicativos web dinâmicos." />
                        </div>
                        <div className='cd-animated-div' data-central-height="700" data-max-offset="50" data-offset-position="0" data-direction="horizontal" data-opacity-transition="1" data-transform-transition="1.2">
                            <Card imageSrc="./src/img/site/icons/mysql.png" text="Um sistema de gerenciamento de banco de dados relacional usado para armazenar dados em aplicativos web." />
                        </div>
                        <div className='cd-animated-div' data-central-height="700" data-max-offset="50" data-offset-position="0" data-direction="horizontal" data-opacity-transition="1" data-transform-transition="1.4">
                            <Card imageSrc="./src/img/site/icons/csharp.png" text="Uma linguagem de programação usada principalmente para desenvolvimento de aplicativos Windows e jogos." />
                        </div>
                        <div className='cd-animated-div' data-central-height="700" data-max-offset="50" data-offset-position="0" data-direction="horizontal" data-opacity-transition="1" data-transform-transition="1.6">
                            <Card imageSrc="./src/img/site/icons/java.png" text="Uma linguagem de programação versátil usada para desenvolvimento de aplicativos, incluindo aplicativos Android." />
                        </div>
                    </div>
                    <div className="cn-height-50"></div>
                </div>
            </div>
        </>
    );
}

export default MainFragment;
/*

            <div>
                <Divider><h1>Formação acadêmica</h1></Divider>
                <div className="cn-horizontal-container cn-max-width">
                    <div className='cn-max-width-50 cn-center-container cd-animated-div' data-central-height="00" data-max-offset="-100" data-offset-position="0" data-direction="horizontal" data-opacity-transition="1" data-transform-transition="0.5">
                        <div className="cn-xlarge cn-margin-top">Graduação</div>
                        <div className="cn-large cn-margin-top">2023: Graduando em Sistema de Informação pela Universidade Federal de Santa Catarina - UFSC. Florianopolis, Brasil</div>
                    </div>
                    <div className='cn-max-width-50 cd-animated-div' data-central-height="00" data-max-offset="100" data-offset-position="0" data-direction="horizontal" data-opacity-transition="1" data-transform-transition="0.5">
                        <img src="./src/img/site/icons/ufsc.png" />
                    </div>
                </div>
                <Divider></Divider>
                <div className="cn-horizontal-container cn-max-width">
                    <div className='cn-max-width-50 cd-animated-div' data-central-height="00" data-max-offset="-100" data-offset-position="0" data-direction="horizontal" data-opacity-transition="1" data-transform-transition="0.5">
                        <img src="./src/img/site/icons/ifsc.png" />
                    </div>
                    <div className='cn-max-width-50 cn-center-container cd-animated-div' data-central-height="00" data-max-offset="100" data-offset-position="0" data-direction="horizontal" data-opacity-transition="1" data-transform-transition="0.5">
                        <div className="cn-xlarge cn-margin-top">Curso técnico</div>
                        <div className="cn-large cn-margin-top">2000: Curso Técnico de Mecânica pela antiga Escola Técnica Federal de Santa Catarina, hoje Instituto Federal de Santa Catarina - IFSC. Florianópolis, Brasil</div>
                    </div>
                </div>
            </div>
            <div>
                <Divider><h2>Formação Complementar</h2></Divider>
                <div className="cn-horizontal-container cn-max-width">
                    <div className='cn-max-width-50 cn-center-container cd-animated-div' data-central-height="00" data-max-offset="-100" data-offset-position="0" data-direction="horizontal" data-opacity-transition="1" data-transform-transition="0.5">
                        <div className="cn-xlarge cn-margin-top">Graduação</div>
                        <div className="cn-large cn-margin-top">2023: Graduando em Sistema de Informação pela Universidade Federal de Santa Catarina - UFSC. Florianopolis, Brasil</div>
                    </div>
                    <div className='cn-max-width-50 cd-animated-div' data-central-height="00" data-max-offset="100" data-offset-position="0" data-direction="horizontal" data-opacity-transition="1" data-transform-transition="0.5">
                        <img src="./src/img/site/icons/ufsc.png" />
                    </div>
                </div>
                <Divider></Divider>
                <div className="cn-horizontal-container cn-max-width">
                    <div className='cn-max-width-50 cd-animated-div' data-central-height="00" data-max-offset="-100" data-offset-position="0" data-direction="horizontal" data-opacity-transition="1" data-transform-transition="0.5">
                        <img src="./src/img/site/icons/ifsc.png" />
                    </div>
                    <div className='cn-max-width-50 cn-center-container cd-animated-div' data-central-height="00" data-max-offset="100" data-offset-position="0" data-direction="horizontal" data-opacity-transition="1" data-transform-transition="0.5">
                        <div className="cn-xlarge cn-margin-top">Curso técnico</div>
                        <div className="cn-large cn-margin-top">2000: Curso Técnico de Mecânica pela antiga Escola Técnica Federal de Santa Catarina, hoje Instituto Federal de Santa Catarina - IFSC. Florianópolis, Brasil</div>
                    </div>
                </div>
            </div>
            */