import React from 'react';
import { Panel } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

const Card = ({ imageSrc, text }) => {
    return (
        <Panel bordered shaded style={{
            backgroundColor: 'rgba(0, 0, 0, 0.08)', margin: '10px', transp: 0.2,
            border: 0, marginTop: 50, width: 300, height: '380px'
        }}>
            <div style={{ width: 256, height: 256, textAlign: 'center' }}>
                <img src={imageSrc} alt="Card" style={{ width: 256, height: 256, objectFit: 'cover' }} />
            </div>
            <div style={{ fontSize: '15px', marginTop: '10px', textAlign: 'center' }}>
                <p style={{ color: '#000000' }}>{text}</p>
            </div>
        </Panel>
    );
};

export default Card;
