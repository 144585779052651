import './styles/start-container-style.css';
import { Container, Header, Footer, Content, Divider, Button } from 'rsuite';
import { Link } from 'react-router-dom';
import FooterFragment from '../FooterFragment/FooterFragment';
import ScrollAnimatedDivs from '../../components/ScrollAnimatedDivs/ScrollAnimatedDivs';

function StartContainer({ children }) {
    return (
        <ScrollAnimatedDivs>
            <Container>
                <Header>
                    <div className='cn-fixed-top'>
                        <div>
                            <Link to='/'><span className='merriweather-light'>CARLOS</span> | <span className='merriweather-black'>DUARTE</span></Link>
                            <div id='cn-menu-top'>
                                <Link to='/' className='links'>Sobre Mim</Link><Divider vertical/>
                                <Link to='/' className='links'>Formação Acadêmica</Link><Divider vertical/>
                                <Link to='/' className='links'>Atuação</Link><Divider vertical/>
                                <Link to='/' className='links'>Produção</Link><Divider vertical/>
                                <Link to='/' className='links'>Contato</Link>
                            </div>
                            <div id='cn-menu-dropdown'><Button>...</Button></div>
                        </div>
                    </div>
                </Header>
                <Content>
                    {children}
                </Content>
                <Footer>
                    <FooterFragment />
                </Footer>
            </Container>
        </ScrollAnimatedDivs>
    );
}

export default StartContainer;
