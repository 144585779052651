import { useState, useEffect } from 'react';

const useEmailValidation = (email) => {
  const [isValid, setIsValid] = useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    setIsValid(emailRegex.test(email));
  }, [email]);

  return isValid;
};

export default useEmailValidation;