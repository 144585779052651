import React from 'react';
import { Button, Col, Drawer, Grid, Input, MaskedInput, Row } from "rsuite";
import EmailFillIcon from '@rsuite/icons/EmailFill';
import useEmailDrawerForm from "./Hooks/useEmailDrawerForm";

const EmailDrawerForm = ({ open, setOpen, placement }) => {
    const {
        formData,
        phoneMask,
        isDisabled,
        loading,
        handleChange,
        handleChangePhone,
        handleBlur,
        handleSubmit,
        resetForm,
        isEmailValid,
        isPhoneValid,
        isNameValid,
        isSubjectValid,
        isMessageValid,
        touched
    } = useEmailDrawerForm();

    return (
        <Drawer placement={placement} open={open} onClose={() => {setOpen(false); resetForm();}} className='rs-drawer'>
            <Drawer.Header>
                <Drawer.Title>Envie sua mensagem</Drawer.Title>
                <Drawer.Actions>
                    <Button disabled={isDisabled} onClick={handleSubmit} appearance="primary" endIcon={<EmailFillIcon />}>
                        {loading ? 'Enviando...' : 'Enviar E-mail'}
                    </Button>
                </Drawer.Actions>
            </Drawer.Header>
            <Drawer.Body className='cn-max-width'>
                <form onSubmit={handleSubmit}>
                    <Grid fluid>
                        <Row className='cn-negative-margin'>
                            <Col xs={24} sm={8} md={8} className='cn-footer-margin'>
                                Nome: <Input
                                    name="name"
                                    placeholder='Seu nome'
                                    value={formData.name}
                                    onChange={(value) => handleChange(value, 'name')}
                                    onBlur={() => handleBlur('name')}
                                />
                                {touched.name && !isNameValid && <small>Nome deve ter pelo menos 3 caracteres.</small>}
                            </Col>
                            <Col xs={24} sm={8} md={12} className='cn-footer-margin'>
                                Email: <Input
                                    name="email"
                                    placeholder='Seu e-mail'
                                    value={formData.email}
                                    onChange={(value) => handleChange(value, 'email')}
                                    onBlur={() => handleBlur('email')}
                                />
                                {touched.email && !isEmailValid && <small>Email inválido.</small>}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={8} md={8} className='cn-footer-margin'>
                                Celular:
                                <MaskedInput
                                    value={formData.phone}
                                    mask={phoneMask}
                                    showMask={true}
                                    keepCharPositions={true}
                                    placeholderChar={'_'}
                                    onChange={handleChangePhone}
                                    onBlur={() => handleBlur('phone')}
                                />
                                {touched.phone && !isPhoneValid && <small>Telefone inválido. Formato esperado: (xx) xxxx-xxxx ou (xx) 9xxxx-xxxx</small>}
                            </Col>
                            <Col xs={24} sm={8} md={8} className='cn-footer-margin'>
                                WhatsApp: <MaskedInput
                                    value={formData.whatsapp}
                                    mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                    showMask={true}
                                    keepCharPositions={true}
                                    placeholderChar={'_'}
                                    onChange={(value) => handleChange(value, 'whatsapp')}
                                    onBlur={() => handleBlur('whatsapp')}
                                />
                            </Col>
                        </Row>
                    </Grid>
                    <div className='cn-footer-margin'>Assunto: <Input
                        name="subject"
                        placeholder='Digite o assunto da mensagem'
                        value={formData.subject}
                        onChange={(value) => handleChange(value, 'subject')}
                        onBlur={() => handleBlur('subject')}
                    />
                        {touched.subject && !isSubjectValid && <small>Assunto deve ter pelo menos 5 caracteres.</small>}
                    </div>
                    <div>Mensagem: <Input
                        name="message"
                        as="textarea"
                        rows={2}
                        placeholder="Digite sua mensagem"
                        value={formData.message}
                        onChange={(value) => handleChange(value, 'message')}
                        onBlur={() => handleBlur('message')}
                    />
                        {touched.message && !isMessageValid && <small>Mensagem deve ter pelo menos 10 caracteres.</small>}
                    </div>
                </form>
            </Drawer.Body>
        </Drawer>
    );
};

export default EmailDrawerForm;
