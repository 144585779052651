import React from 'react';
import { animated } from 'react-spring';
import useAnimatedItem from './Hooks/useAnimatedItem';

const AnimatedItem = ({ children, distance, direction, duration, delay, onAnimationEnd }) => {
    const { ref, animationProps } = useAnimatedItem(distance, direction, duration, delay, onAnimationEnd);

    return (
        <animated.div ref={ref} style={animationProps}>
            {children}
        </animated.div>
    );
};

export default AnimatedItem;
