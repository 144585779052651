import { useState, useEffect } from 'react';

const usePhoneValidation = (phone) => {
  const [isValid, setIsValid] = useState(false);
  const phoneRegex = /^\(\d{2}\) 9?\d{4}-\d{4}$/

  useEffect(() => {
    setIsValid(phoneRegex.test(phone));
  }, [phone]);

  return isValid;
};

export default usePhoneValidation;