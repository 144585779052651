import { Link } from 'react-router-dom';
import './footer-fragment.css';
import { Divider } from "rsuite";
import React, { useState } from 'react';
import EmailDrawerForm from './../EmailDrawerForm/EmailDrawerForm';

function FooterFragment() {
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState();

    const handleOpen = key => {
        setOpen(true);
        setPlacement(key);
    };

    return (
        <div className='cn-background-body'>
            <Divider><h1>Contatos</h1></Divider>
            <div className='cd-animated-div' data-central-height="300" data-max-offset="-100" data-offset-position="-400" data-direction="vertical"
                data-opacity-transition="1" data-transform-transition="1">
                <Link to="https://wa.me/55048984148790" target="_blank" >
                    <img src="./src/img/site/icons/whatsapp.png" style={{ width: 50, height: 'auto' }} />
                </Link>
                <Divider vertical />
                <img src="./src/img/site/icons/email.png" style={{ width: 50, height: 'auto' }} onClick={() => handleOpen('bottom')} />
            </div>
            <div>Ⓒ 2024 Calos Duarte.</div>
            <EmailDrawerForm open={open} setOpen={setOpen} placement={placement} />
        </div>
    );
}

export default FooterFragment;
